// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';

import { Toast, Link, Button } from '~components';
import { useLocalStorage } from '~utils/';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function CookieContainer() {
  const [isVisible, setIsVisible] = useLocalStorage('isShowCookies', true);

  return (
    <Toast
      backgroundColor="var(--color-inverse)"
      animation="none"
      color="var(--color-text)"
      // bottom={{ xs: 'auto', sm: '0' }}
      // top={{ xs: '0', sm: 'auto' }}
      bottom="0"
      top="auto"
      isVisible={isVisible}
    >
      Tento web používá cookies, více info v naší{' '}
      <Link look="primary" to="/pravni-podminky/">
        cookie policy
      </Link>
      <Button
        look="primary"
        margin="0 0 0 4rem"
        padding="1rem 4rem"
        fontSize="1.5rem"
        onClick={() => setIsVisible(!isVisible)}
      >
        Přijmout
      </Button>
    </Toast>
  );
}
