// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FaSquareFacebook, FaSquareXTwitter, FaSquareYoutube, FaLinkedin } from 'react-icons/fa6';

import { Footer, Section, H2, P, Ul, Li, Link, Form, Input, Button } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function FooterContainer({ location }) {
  if (location.pathname.includes('/en/')) {
    return null;
  }

  const { footer } = useStaticQuery(graphql`
    query {
      footer: mdx(
        fields: { sourceName: { eq: "menus" } }
        frontmatter: { title: { eq: "Footer" } }
      ) {
        frontmatter {
          links {
            # mdx
            type
            url
            title
            links {
              text
              url
            }
          }
        }
      }
    }
  `);

  return (
    <Footer>
      {/* <Section padding="2rem var(--width-outside)">
        <H1 fontSize="3rem" textAlign="center" margin="0 0 2rem">
          Přihlas se do našeho newsletteru
        </H1>
        <Form
          gridTemplate={{
            xs: "'email' 'button'",
            lg: "'email button' / 2fr 1fr",
          }}
          padding={{
            lg: '0 40rem',
          }}
        >
          <Input type="email" name="email" placeholder="Email" label="Email" fontWeight="700" />
          <Button gridArea="button" look="primary">
            Přihlásit
          </Button>
        </Form>
        <P textAlign="center">
          Zadej svůj email a získávej zajímavosti a informace o soutěži.
        </P>
      </Section> */}
      <Section padding="8rem var(--width-outside)">
        <Ul
          gridAutoFlow={{
            lg: 'column',
          }}
          gridGap="2rem"
        >
          {footer.frontmatter.links.map((category) => (
            <Li key={category.title}>
              <H2 fontWeight="700" fontSize="3rem">
                {category.title}
              </H2>
              <Ul>
                {category.links.map((link) => (
                  <Li key={link.url || link.text} lineHeight="3rem">
                    {link.url ? (
                      <Link
                        to={link.url}
                        look="tertiary"
                        textTransform="uppercase"
                        letterSpacing="0.1em"
                        fontSize="1.5rem"
                      >
                        {link.text}
                      </Link>
                    ) : (
                      <H2 fontWeight="700" fontSize="3rem" margin="2rem 0 0">
                        {link.text}
                      </H2>
                    )}
                  </Li>
                ))}
              </Ul>
            </Li>
          ))}
        </Ul>
      </Section>
      <Section
        padding="2rem var(--width-outside)"
        css={`
          --shadow: inset 0 1px hsla(var(--hsl-text), 0.1);
          box-shadow: var(--shadow);
        `}
      >
        <P>
          Sleduj nás na sociálních médiích:{' '}
          <Link to="https://www.linkedin.com/company/vodafone-n%C3%A1pad-roku/" look="secondary">
            <FaLinkedin fontSize="3rem" style={{ verticalAlign: 'text-bottom' }} />
          </Link>
          <Link to="https://facebook.com/napadroku/" look="secondary">
            <FaSquareFacebook fontSize="3rem" style={{ verticalAlign: 'text-bottom' }} />
          </Link>
          <Link to="https://x.com/napadroku/" look="secondary">
            <FaSquareXTwitter fontSize="3rem" style={{ verticalAlign: 'text-bottom' }} />
          </Link>
          <Link to="https://youtube.com/user/napadroku/" look="secondary">
            <FaSquareYoutube fontSize="3rem" style={{ verticalAlign: 'text-bottom' }} />
          </Link>
        </P>
      </Section>
      <Section
        padding="2rem var(--width-outside)"
        css={`
          --shadow: inset 0 1px hsla(var(--hsl-text), 0.1);
          box-shadow: var(--shadow);
        `}
      >
        <Link to="/pravni-podminky/" look="secondary">
          Právní podmínky a ochrana osobních údajů
        </Link>
      </Section>
    </Footer>
  );
}
